<template>
  <div
    class="cellular-module-box px-cellular-module-box"
    ref="cellular-module-box"
    :style="`background-image:url('${configs.bg}');background-color:${configs.bgColor};`"
  >
    <div
      class="bigTitle"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      @click="clickItem('bigTitle')"
    ></div>
    <div
      class="bigDesc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      @click="clickItem('bigDesc')"
    ></div>
    <div
      id="container"
      class="container"
      :class="{isEdit:isEdit}"
      ref="container"
    >
      <!-- 外框 + 投影在這裡 -->
      <div
        v-for="(item,index) in allGrid"
        :key="index"
        class="grid"
        :class="{hasImg:item.img}"
        :style="{left:`${item.x}rem`,top:`${item.y}rem`}"
        @click="clickItem('container',index)"
      >
        <!-- 六邊形   -->
        <div class="child">
          <progressiveImage
            class="img"
            :src="item.img"
            :h="30"
          />
          <div
            v-if="isEdit"
            class="before"
          >{{index}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import { TweenMax, TweenLite } from "gsap";
import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
export default {
  name: "cellular-module",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {}
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  watch: {
    "configs.gridRows": {
      deep: true,
      handler () {
        if (this.isEdit) {
          this.initItems();
        }
      }
    },
    "configs.gridColumns": {
      deep: true,
      handler () {
        if (this.isEdit) {
          this.initItems();
        }
      }
    },
    "configs.items": {
      deep: true,
      handler () {
        if (this.isEdit) {
          this.initItems();
        }
      }
    }
  },
  data () {
    return {
      allGrid: [],
    };
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    },
    isPC () {
      return window.innerWidth > 768;
    }
  },
  mounted () {
    this.initItems();

    trigger.add(this.$refs['cellular-module-box'], {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.2
          }
        },
        viewport: {
          y: (trigger, frame, direction) => {
            return 0;// trigger.visible ? 0 : 0.2
          }
        },
      },
      toggle: {
        callback: {
          in: () => {
            // 設置標題動畫
            TweenMax.from(this.$refs['cellular-module-box'].querySelector('.bigTitle'), {
              opacity: 0,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            // 設置 描述動畫
            TweenMax.from(this.$refs['cellular-module-box'].querySelector('.bigDesc'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })

            // 設置 描述動畫
            TweenMax.from(this.$refs['cellular-module-box'].querySelector('#container'), {
              opacity: 0,
              delay: 0.6,
              y: 120,
              duration: 1,
              immediateRender: true
            })
          },
          out: () => {
            // this.showNumber = false;
          },
        }
      }
    })
  },
  methods: {
    initItems () {
      let { gridRows, gridColumns, items } = this.configs;
      let w = window.innerWidth;
      let gridWidth = 130,
        gridWidth2 = gridWidth / 2,
        gridHeight = 150,
        padding = 4,
        i, x, y, p = 0,
        gridArr = [],
        yxc = 34;

      if (w < 768) {
        // 移動端
        gridWidth = 180;
        gridHeight = this.$math.divide(gridWidth, 0.87);
        gridWidth2 = this.$math.divide(gridWidth, 2);
        gridColumns = 9;
        yxc = 47;
        // 排序
        items.sort((a, b) => {
          return a.index - b.index
        })
        // 移除空
        for (let i = 0; i < items.length; i += 1) {
          if (!items[i].img) {
            items.splice(i, 1)
            i -= 1;
          }
        }

        console.log(items);
      }

      let forLen = gridRows * gridColumns;
      if (w < 768) forLen -= Math.floor(gridRows / 2)
      if (forLen < items.length)
        forLen = items.length
      let fori = 0;
      for (i = 0; i < gridRows * gridColumns; i++) {
        let count = Math.floor(i / gridColumns);
        y = Math.floor(i / gridColumns) * gridHeight;
        x = (i * gridWidth) % (gridColumns * gridWidth)
        if (i % gridColumns == 0) {
          p = 0;
        } else {
          p += padding;
        }
        x += p;
        if (count % 2 == 1) x += (gridWidth2 + (padding / 2));

        if (count > 0) y -= count * yxc;
        let json = {};
        json = {
          x: x / 192,
          y: y / 192,
          w: "",
          h: ""
        }
        if (w > 768) {
          for (let j = 0; j < items.length; j++) {
            const element = items[j];
            if (element.index == i) {
              json.img = element.img;
              break;
            }
          }
        } else {
          if (count % 2 == 1 && i % gridColumns == 8) {
            json.img = "";
          } else {
            json.img = items[i]?.img
            fori += 1;
          }
        }
        gridArr.push(json)
      }
      this.allGrid = gridArr
      if (w > 768) {
        TweenLite.set(this.$refs.container, { height: (gridRows * gridHeight + 1) / 192 + `rem`, width: (gridColumns * (gridWidth + padding) + 1 + gridWidth2) / 192 + `rem` });
      } else {
        TweenLite.set(this.$refs.container, { height: (gridRows * gridHeight + 1) / 192 + `rem`, width: (gridColumns * (gridWidth + padding) + 1) / 192 + `rem` });
      }

    },
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  }

}
</script>

<style lang="less" scoped>
// @media screen and(min-width:768px) {
.cellular-module-box {
  padding: 70px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .bigTitle {
    font-size: 44px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
  }
  .bigDesc {
    font-size: 26px;
    font-family: "Microsoft Yahei";
    font-weight: 200;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    margin-top: 16px;
  }
  #container {
    position: relative;
    margin: 70px auto 0;
    &.isEdit {
      .grid {
        .child {
          background-color: #efefef;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .before {
            font-size: 40px;
            font-weight: 800;
            text-shadow: 0px 0px 3px #fff;
            position: absolute;
            // background-color: rgba(33, 33, 33, 0.15);
          }
        }
      }
    }
    .grid {
      position: absolute;
      width: 130px;
      height: 150px;
      .child {
        height: 100%;
        width: 100%;
        clip-path: polygon(
          0px 25%,
          50% 0px,
          100% 25%,
          100% 75%,
          50% 100%,
          0px 75%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        .img {
          --wh: calc(100% - 20px);
          max-width: var(--wh);
          max-height: var(--wh);
        }
      }
      --tranS: 0.8s;
      transition: var(--tranS);
      &.hasImg {
        .child {
          background-color: #fff;
          transition: var(--tranS);
          height: 100%;
          width: 100%;
        }
        &:hover {
          z-index: 20;
          transform: scale(1.2);
          filter: drop-shadow(6px 6px 6px rgba(33, 33, 33, 0.15));
          .child {
            transform: scale(1.2) rotateY(360deg);
          }
        }
      }
    }

    .drag_item {
      position: absolute;
      width: 130px;
      height: 150px;
      clip-path: polygon(
        0px 25%,
        50% 0px,
        100% 25%,
        100% 75%,
        50% 100%,
        0px 75%
      );
      background-color: aquamarine;
    }
  }
}
// }

@media screen and(max-width:768px) {
  .px-cellular-module-box {
    padding: 34px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .bigTitle {
      font-size: 22px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
    }
    .bigDesc {
      margin-top: 4px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 0 54px;
      text-align: center;
      padding: 0 50px;
    }
    #container {
      position: relative;
      margin: 18px auto 0;
    }
  }
  .cellular-module-box {
    #container {
      .grid {
        position: absolute;
        --gridW: 180px;
        width: var(--gridW);
        height: calc(var(--gridW) / 0.87);
        &:hover {
          transform: none !important;
          .child {
            transform: none !important;
          }
        }
        .child {
          height: 100%;
          width: 100%;
          clip-path: polygon(
            0px 25%,
            50% 0px,
            100% 25%,
            100% 75%,
            50% 100%,
            0px 75%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        transition: 0.5s;
      }
      .drag_item {
        position: absolute;
        width: 130px;
        height: 150px;
        clip-path: polygon(
          0px 25%,
          50% 0px,
          100% 25%,
          100% 75%,
          50% 100%,
          0px 75%
        );
        background-color: aquamarine;
      }
    }
  }
}

@media screen and(max-width:600px) {
  .px-cellular-module-box {
    padding: 34px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .bigTitle {
      font-size: 20px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
    }
    .bigDesc {
      margin-top: 4px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 0 54px;
      text-align: center;
      line-height: 20px;
    }
    #container {
      position: relative;
      margin: 18px auto 0;
    }
  }
  .cellular-module-box {
    #container {
      .grid {
        position: absolute;
        --gridW: 180px;
        width: var(--gridW);
        height: calc(var(--gridW) / 0.87);
        &:hover {
          transform: none !important;
          .child {
            transform: none !important;
          }
        }
        .child {
          height: 100%;
          width: 100%;
          clip-path: polygon(
            0px 25%,
            50% 0px,
            100% 25%,
            100% 75%,
            50% 100%,
            0px 75%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        transition: 0.5s;
      }
      .drag_item {
        position: absolute;
        width: 130px;
        height: 150px;
        clip-path: polygon(
          0px 25%,
          50% 0px,
          100% 25%,
          100% 75%,
          50% 100%,
          0px 75%
        );
        background-color: aquamarine;
      }
    }
  }
}
</style>